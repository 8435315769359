import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import axios from "axios";

function DisplayBreadcrumbs({ title }) {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (paths.includes("recurso")) {
      axios
        .get(
          `${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/${paths[2]}?show-reference-ids`
        )
        .then((res) => {
          if (isMounted) {
            const distribution = res.data.distribution;
            let breadcrumbName = "Recurso";
            for (let i = 0; i < distribution.length; i++) {
              if (distribution[i].identifier === paths[4]) {
                breadcrumbName = distribution[i].data.title;
                break;
              }
            }

            // Verifica si el nuevo estado es diferente al estado actual antes de actualizar
            if (
              JSON.stringify(breadcrumbs) !==
              JSON.stringify([
                { name: res.data.title, href: `/${paths[1]}/${paths[2]}` },
                {
                  name: breadcrumbName,
                  href: `/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}`,
                },
              ])
            ) {
              setBreadcrumbs([
                { name: res.data.title, href: `/${paths[1]}/${paths[2]}` },
                {
                  name: breadcrumbName,
                  href: `/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}`,
                },
              ]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Verifica si el nuevo estado es diferente al estado actual antes de actualizar
      if (
        JSON.stringify(breadcrumbs) !==
        JSON.stringify([{ name: title, href: `/${paths[1]}/${paths[2]}` }])
      ) {
        setBreadcrumbs([{ name: title, href: `/${paths[1]}/${paths[2]}` }]);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [paths, title, breadcrumbs]);

  return (
    <Breadcrumb>
      <Breadcrumb.Item key="/conjunto-de-datos/" href="/conjunto-de-datos/">
        Conjuntos de datos
      </Breadcrumb.Item>
      {breadcrumbs.map((breadcrumb) => (
        <Breadcrumb.Item
          key={breadcrumb.href}
          href={breadcrumb.href}
          active={breadcrumb === breadcrumbs[breadcrumbs.length - 1]}
        >
          {breadcrumb.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default DisplayBreadcrumbs;
