import React from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ZipFiles = ({ item }) => {
  const createZipFile = () => {
    const zip = new JSZip();
    const promises = [];
    // Recorre cada objeto en el arreglo "distribution" y agrega su archivo al archivo zip
    item.distribution.forEach((dist) => {
      if (dist.data.downloadURL) {
        // Usa la función fetch para obtener el archivo y convertirlo en un objeto blob
        const promise = fetch(dist.data.downloadURL, { mode: "no-cors" })
          .then((res) => res.blob())
          .then((blob) => {
            zip.file(dist.data.title + "." + dist.data.format, blob); // Agrega el archivo al archivo zip
          })
          .catch((err) => {
            console.log(err);
          });
        promises.push(promise);
      }
    });

    // Genera el archivo zip y lo descarga en el navegador
    Promise.all(promises).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `${item.title}.zip`);
      });
    });
  };

  return (
    <button onClick={createZipFile} className="bt-styled">
      <FontAwesomeIcon icon={["fas", "download"]} size="1x" />
      &nbsp;Descargar todos
    </button>
  );
};

export default ZipFiles;
