import React from "react";
import Layout from "../../components/Layout";
const NotFound = () => {
  return (
    <>
      <Layout title="No encontrado">
        <div className="dc-not_found-page container">
          <h2>No se ha encontrado la página solicitada.</h2>
        </div>
      </Layout>
    </>
  );
};

export default NotFound;
