import { Link } from "@reach/router";
import React from "react";
import Stats from "./Stats";
const SecHeader = () => {
  return (
    <div className="dc-sec-header">
      <div className="dc-sub-site-name">
        <a href="/conjunto-de-datos/">
          <h4>Inicio</h4>
        </a>
        <h1>Datos abiertos</h1>
      </div>
      <div className="featured-stats">
        <Stats />
      </div>
      <div className="about-link">
        <Link to="/acerca-de">Acerca de este sitio</Link>
      </div>
    </div>
  );
};

export default SecHeader;
