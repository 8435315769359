import React from "react";
import Layout from '../../components/Layout';
import config from "../../assets/config";

const About = () => (
    <Layout title="Acerca De">
        <div className={`dc-page ${config.container}`}>
            <h1>Acerca de</h1>
            <div className="dc-page-content row">
                <div className="col-md-9 col-sm-12">
                    <p>Un dato es abierto cuando está disponible de manera simple y gratuita, en formatos digitales para ser reutilizado.</p>
                    <p>Se trata de una filosofía y práctica empleada con el objetivo de brindar datos sin restricciones técnicas ni legales, permitiendo que la información pueda ser redistribuida y reutilizada por los ciudadanos.</p>
                    <p><strong>¿Por qué publicamos datos abiertos?</strong></p>
                    <p>Compartimos datos con el objetivo de seguir trabajando en la transparencia de la gestión e impulsar el desarrollo de nuevos servicios que beneficien a los ciudadanos. Creemos que un ciudadano bien informado puede tomar mejores decisiones. La gestión y manipulación de datos abiertos permite al ciudadano adoptar un rol más activo, participando en los asuntos públicos, ayudando al municipio en la elaboración de las políticas públicas y creando propuestas de valor.</p>
                    <p>La apertura contribuye a la igualdad de oportunidades en la comunidad y fomenta prácticas de participación y colaboración.</p>
                    <p><strong>¿Cuál es el marco legal relacionado?</strong></p>
                    <p>La República Argentina en 2014 sancionó la Ley Nº 27078 de Tecnologías de la Información y las Comunicaciones donde se especifica el Plan de Acción de Gobierno Abierto, posibilitando una normativa para el acceso a todos los habitantes de la nación a los servicios de la información y las comunicaciones en condiciones sociales y demográficas equitativas, procurando la accesibilidad y asequibilidad. En la actualidad se están estableciendo pautas para el 2° Plan Nacional de Gobierno Abierto, conforme a las especificaciones dadas por organismos internacionales.</p>
                    <p>En la provincia de Santa Fe el Decreto Nº 0692/2009 constituye el primer proyecto provincial para cumplir los estándares internacionales respecto al acceso de la información pública como derecho colectivo.</p>
                    <p>En Rosario el concejo municipal sancionó la <a href="http://www.rosario.gov.ar/normativa/ver/visualExterna.do?accion=verNormativa&amp;idNormativa=103362">Ordenanza Nº 9279</a> el 12 de septiembre de 2014 creando el Programa Open Data Rosario, fundamentado en el derecho de todas las personas al acceso de la información pública.</p>
                    <p><strong>¿Cómo se ofrecen los datos en este sitio web?</strong></p>
                    <p>Los datos se encuentran clasificados en conjuntos de datos (dataset) que son a su vez agrupados en diferentes categorías.<br />
                        Cada conjunto de datos está constituido por uno o más recursos (archivos digitales en la mayoría de los casos) en diferentes formatos (csv, gml, entre otros).</p>
                    <p><strong>¿Qué herramienta de software utilizamos?</strong></p>
                    <p>Implementamos <a href="http://docs.getdkan.com/">DKAN</a> que es un software de código abierto para gestionar datos abiertos, desarrollado con <a href="https://www.drupal.org/">Drupal</a>.</p>
                </div>
            </div>
        </div>
    </Layout>
);

export default About;
