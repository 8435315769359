import React from "react";
import logoFooter from "./../../../theme/images/logo_mobile.svg"
const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="row ">
          <div className="col-1-sitio">
            <section className="block-sitio">
              <h2>Acerca del sitio</h2>
              <ul>
                <li>
                  <a href="https://www.rosario.gob.ar/inicio/terminos-y-condiciones"  target="_blank" rel="noopener noreferrer"   >
                    Términos y condiciones 
                  </a>
                </li>
                
              </ul>
            </section>
          </div>
          <div className="col-2-gobierno">
            <section className="block-gobierno">
              <h2>Acerca del gobierno</h2>
              <a href="https://www.rosario.gob.ar/inicio/autoridades-y-secretarias"  target="_blank" rel="noopener noreferrer"  >
                Autoridades y secretarías
              </a>
            </section>
          </div>
        </div>
        <div className="row">
          <div className="col-1-accesos">
            <section className="acessos-rapidos">
              <h2>Accesos rápidos</h2>
              <ul>
                <li>
                  <a href="https://www.rosario.gob.ar/inicio/"  target="_blank" rel="noopener noreferrer"  >
                    Rosario.gob.ar
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.rosario.gob.ar/inicio/autoridades-y-secretarias"
                    tabIndex="-1"  target="_blank" rel="noopener noreferrer"  
                  >
                    Autoridades y secretarías
                  </a>
                </li>
                <li>
                  <a href="https://www.rosarionoticias.gob.ar/"  target="_blank" rel="noopener noreferrer"  >
                    Rosario Noticias
                  </a>
                </li>
                <li>
                  <a href="https://participa.rosario.gob.ar/"  target="_blank" rel="noopener noreferrer"  >
                    Rosario Participa
                  </a>
                </li>
                <li>
                  <a href="https://www.rosario.gob.ar/normativa/visualExterna/normativas.jsp"  target="_blank" rel="noopener noreferrer"  >
                    Normativa
                  </a>
                </li>
                <li>
                  <a href="https://www.rosario.gob.ar/sitio/licitaciones/buscarLicitacionVisual.do"  target="_blank" rel="noopener noreferrer"  >
                    Licitaciones
                  </a>
                </li>
              </ul>
            </section>
          </div>
          <div className="col-2-redes">
            <section className="redes-sociales">
              <h2>Redes sociales</h2>
              <ul>
                <li>
                  <a
                    ahref="https://www.facebook.com/municipalidadrosario"
                    href="https://www.facebook.com/municipalidadrosario"
                     target="_blank" rel="noopener noreferrer"  
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/muni_rosario/"  target="_blank" rel="noopener noreferrer"  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/MuniRosario"  target="_blank" rel="noopener noreferrer"  >Twitter</a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/user/Munirosario"
                     target="_blank" rel="noopener noreferrer"  
                  >
                    Youtube
                  </a>
                </li>
              </ul>
            </section>
          </div>
          <div className="col-3-logo">
            <section className="logo-mdr">
              <img
                src={logoFooter}
                alt="Escudo rosario"
              />
            </section>
          </div>
        </div>
        <div className="row row-desarrollo">
          <section>
            <p className="p-desarrollo">
              Todos los contenidos se presentan bajo licencia Creative Commons
              4.0 salvo especificación contraria.
            </p>
            <p className="p-desarrollo">
              Municipalidad de Rosario. Buenos Aires 711, Rosario, Santa Fe,
              Argentina (1997 - 2023).
            </p>
          </section>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
