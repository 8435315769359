import React from "react";

export const defaultFacets = {
  theme: {
    label: "Temas",
    field: "theme.0.title",
    showAll: false,
    limit: 10,
    reset: {
      active: false,
      icon: <span></span>,
    },
  },
  keyword: {
    label: "Etiquetas",
    field: "keyword.*.title",
    showAll: false,
    limit: 10,
    reset: {
      active: false,
      icon: <span></span>,
    },
  },
  publisher__name: {
    label: "Autores",
    field: "publisher__name",
    showAll: false,
    limit: 10,
    reset: {
      active: false,
      icon: <span></span>,
    },
  },
  distribution__item__format: {
    label: "Formatos",
    field: "distribution__item__format",
    showAll: false,
    limit: 10,
    reset: {
      active: false,
      icon: <span></span>,
    },
  },
};

export function normalizeItems(resultItems) {
  let nItems = resultItems;
  if (!Array.isArray(nItems)) {
    nItems = Object.values(nItems);
  }
  return nItems.map((x) => {
    let item = {};
    item = {
      title: x.title,
      identifier: x.identifier,
      description: x.description,
      theme: x.theme,
      ref: `/dataset/${x.identifier}`,
    };
    if (Object.prototype.hasOwnProperty.call(x, "modified")) {
      const modified = x["modified"];
      const fechaObjeto = new Date(modified).toLocaleDateString("es-AR");
      item.modified = fechaObjeto; // Almacenar la fecha como objeto Date
    }
    if (Object.prototype.hasOwnProperty.call(x, "issued")) {
      const issued = x["issued"];
      const fecha = new Date(issued).toLocaleDateString("es-AR");
      item.issued = fecha; // Almacenar la fecha como objeto Date
    }
    if (
      Object.prototype.hasOwnProperty.call(x, "publisher") &&
      Object.prototype.hasOwnProperty.call(x.publisher, "name")
    ) {
      item.publisher = x.publisher.name;
    } else {
      item.publisher = "";
    }
    if (Object.prototype.hasOwnProperty.call(x, "license")) {
      item.license = x.license;
    } else {
      item.license = "";
    }
    if (Object.prototype.hasOwnProperty.call(x, "distribution")) {
      item.format = x.distribution;
    } else {
      item.format = "";
    }
    return item;
  });
}

export const sortOptions = [
  {
    field: "modified",
    order: "desc",
    label: "Fecha de Actualización",
  },
  {
    field: "issued",
    order: "asc",
    label: "Fecha de Creación",
  },
  {
    field: "title",
    order: "asc",
    label: "Alfabético",
  },
];
