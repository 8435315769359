import React from "react";
import PropTypes from "prop-types";
import excerpts from "excerpts";
import iconos from "../../../../assets/topics.json";
import DataIcon from "@civicactions/data-catalog-components/lib/components/DataIcon";
import Text from "@civicactions/data-catalog-components/lib/components/Text";
import { Link } from "@reach/router";
import { countBy } from "lodash";

const SearchListItem = ({ className, item }) => {
  const { ref, title, description, publisher, format, theme, identifier } = item;

  function formats(distribution) {
    if (!distribution) {
      return null;
    }

    if (typeof distribution === "object" || Array.isArray(distribution)) {
      const distributionWithUniqueFormats = getUniqueFormats(
        Object.entries(distribution)
      );
      const counted = countBy(distribution, (d) => d.format);
      let count = 0;
      return distributionWithUniqueFormats.map((dist, index) => {
        const type = dist.mediaType ? dist.mediaType.split("/") : "";
        const backup = type ? type : "data";
        const format = dist.format ? dist.format : backup;
        if (format === "data") {
          count++;
        }
        return (
          <div key={`dist-id-${identifier}-${index}`}>
            {counted[format] ? counted[format] : count}x{" "}
            <div
              title={`format: ${dist.format}`}
              className="label"
              data-format={format}
              key={`format-${identifier}-${index}`}
            >
              {format}
            </div>
          </div>
        );
      });
    }

    return null;
  }

  function themes(theme) {
    if (!theme) {
      return null;
    } else {
      return theme.map((topic, index) => {
        let image = iconos.topics.reduce(
          (url, icono) => (icono.name === topic ? icono.url : url),
          "/temas/ciudadanos_y_estado.png"
        );

        return (
          <div className="dc-topic-wrapper" key={`theme-${identifier}-${index}`}>
            <img src={image} alt={topic} key={`img-${identifier}-${index}`} />
            {topic}
          </div>
        );
      });
    }
  }

  function publishers(publisher) {
    if (!publisher) {
      return null;
    } else {
      return (
        <span>
          <DataIcon icon="group" height={20} width={20} />
          {publisher}
        </span>
      );
    }
  }

  return (
    <div className={className}>
      <h2>
        <Link to={ref}>{title}</Link>
      </h2>
      {publisher !== "undefined" && (
        <div className="dc-item-publisher">{publishers(publisher)}</div>
      )}
      {theme && <div className="dc-item-theme">{themes(theme)}</div>}
      {description && (
        <div className="dc-item-description">
          <Text>{excerpts(description, { words: 35 })}</Text>
        </div>
      )}
      {format && <div className="format-types">{formats(format)}</div>}
    </div>
  );
};

const getUniqueFormats = (formats) => {
  const unique = [];
  return formats.reduce((a, b) => {
    if (unique.indexOf(b[1].format) === -1) {
      unique.push(b[1].format);
      a.push(b[1]);
    }
    return a;
  }, []);
};

SearchListItem.defaultProps = {
  className: "dc-search-list-item",
};

SearchListItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SearchListItem;
