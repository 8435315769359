import React from 'react';
import { useLocation } from "@reach/router"
import { defaultFacets, normalizeItems, sortOptions } from '../../config/search';
import Layout from "../../components/Layout";
import config from "../../assets/config.json";
import SearchSidebar from "../../components/Search/SearchSidebar";
import SearchContent from "../../components/Search/SearchContent";
import Search from "../../components/Search";
const SearchTemplate = ({ path }) => {
  const location = useLocation();
  return (
    <Layout title="Buscar | Rosario Datos">
      <div className={`dc-page ${config.container}`}>
        <Search
          searchEndpoint={`${process.env.REACT_APP_ROOT_URL}/search`}
          defaultFacets={defaultFacets}
          sortOptions={sortOptions}
          setSearchUrl={true}
          path={path}
          location={location}
          normalize={normalizeItems}
        >
          <div className="row">
            <SearchSidebar 
              sortOptions={sortOptions}
            />
            <SearchContent />
          </div>
        </Search>
      </div>
    </Layout>
  );
}

export default SearchTemplate;
