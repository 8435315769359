import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "@reach/router";
import Layout from "../../components/Layout";
import config from "../../assets/config";
import { FileDownload } from "@civicactions/data-catalog-components";
import Resource from "../../components/Resource"
import DataTable from "../../components/DataTable";
import DataTableHeader from "../../components/DataTableHeader";
import { Map } from "../../components/Map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DisplayBreadcrumbs from "../../components/Breadcrumbs";

const ResourceTemplate = ({ idResource, location }) => {
  const { state } = location;
  const [toggleView, setToggleView] = useState(true);
  const [resource, setResource] = useState(state && state.data ? state : {});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (state && state.data) {
      setIsLoading(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_ROOT_URL}/metastore/schemas/distribution/items/${idResource}?show-reference-ids`
        )
        .then((res) => {
          setResource(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [idResource, state]);

  const type =
    resource &&
    resource.hasOwnProperty("data") &&
    resource.data.hasOwnProperty("mediaType")
      ? resource.data.mediaType.split("/")
      : "";
  const backup = type ? type[1] : "data";
  const format =
    resource &&
    resource.hasOwnProperty("data") &&
    resource.data.hasOwnProperty("format")
      ? resource.data.format
      : backup;

  const downloadURL =
    resource &&
    resource.hasOwnProperty("data") &&
    resource.data.hasOwnProperty("downloadURL")
      ? resource.data.downloadURL
      : "";
  const accessURL =
    resource &&
    resource.hasOwnProperty("data") &&
    resource.data.hasOwnProperty("accessURL")
      ? resource.data.accessURL
      : downloadURL;

  const verified =
    resource &&
    resource.hasOwnProperty("verified")
      ? darFormatoAr(resource.verified)
      : "";

  const modified =
      resource &&
      resource.hasOwnProperty("modified")
        ? darFormatoAr(resource.modified)
        : "";

  const rootURL = `${process.env.REACT_APP_ROOT_URL}/`;


  function handleResource() {
    if (!toggleView) {
      return <Map idResource={idResource} />;
    } else {
      return (
        <>
          <DataTableHeader />
          <DataTable />
        </>
      );
    }
  }

  function darFormatoAr(date) {
    return new Date(date).toLocaleString("es-AR");
  }

  return (
    <Layout title={`Recurso | Rosario Datos`}>
      {isLoading ? (
        <h1>Cargando...</h1>
      ) : (
        <div className={`dc-dataset-page ${config.container}`}>
          <div id={`resource_${resource.identifier}`}>
            <DisplayBreadcrumbs title={resource.data.title} />
            {format.toLowerCase() === "csv" ? (
              <>
                <div className="resource-menu">
                  <button className="bt-styled">
                    <Link to={`../../`} className="bt-styled">
                      Volver
                    </Link>
                  </button>
                  <button className="bt-styled">
                    <Link to={`api`} state={resource} className="bt-styled">
                    <FontAwesomeIcon icon={["fa", "flask"]} size="1x" />
                      &nbsp;API del recurso
                    </Link>
                  </button>
                </div>
                <Resource
                  apiURL={rootURL}
                  identifier={resource.identifier}
                  resource={resource}
                  showDBColumnNames={true}
                >
                  <FileDownload
                    title={resource.data.title}
                    label={accessURL ? accessURL : downloadURL}
                    format={format}
                    downloadURL={accessURL ? accessURL : downloadURL}
                    description={resource.data.description}
                  />

                  { modified !== "" &&
                    <div className={"mb-3"}>Última modificación {modified} </div>
                  }

                  { verified !== "" &&
                    <div className={"mb-3"}>Última verificación {verified} </div>
                  }

                  <div className="container-resource-data">
                    <button
                      onClick={() => setToggleView(true)}
                      className="bt-styled"
                    >
                      Tabla
                    </button>
                    <button
                      onClick={() => setToggleView(false)}
                      className="bt-styled"
                    >
                      Mapa
                    </button>
                    {handleResource()}
                  </div>
                </Resource>
              </>
            ) : (
              <FileDownload
                title={resource.data.title}
                label={accessURL ? accessURL : downloadURL}
                format={format}
                description={resource.data.description}
                downloadURL={accessURL ? accessURL : downloadURL}
              />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ResourceTemplate;
