import React, { useState } from "react";
import { navigate } from "@reach/router";

const Header = ({ logo }) => {
  const [query, setQuery] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const searchParams = query
      ? `/conjunto-de-datos/?fulltext=${query}`
      : "/conjunto-de-datos/";
    await navigate(searchParams);
  };

  const mostrarBusqueda = () => {
    const actualUrl = window.location.href;
    if (!actualUrl.includes("conjunto-de-datos")) {
      return (
        <div className="hero-view">
          <form onSubmit={handleSubmit} className="dc-hero-search">
            <input
              placeholder=" Buscar datos"
              value={query}
              onChange={(event) => setQuery(event.target.value)}
              id="hero_search"
            />
            <button type="submit">Ir</button>
          </form>
        </div>
      );
    }
  };

  return (
    <div className="dc-header">
      <div className="branding">
        <a
          aria-current="page"
          className="dc-logo"
          href="/conjunto-de-datos/"
        >
          <img src={logo} alt="Escudo Rosario" />
        </a>
        <div className="dc-site-name">
          <h1>
            <a href="/conjunto-de-datos/">
              <span className="marca">datosabiertos</span>
              <span className="sec">.rosario.gob.ar</span>
            </a>
          </h1>
        </div>
      </div>
      <div className="search">{mostrarBusqueda()}</div>
    </div>
  );
};

export default Header;
