import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import axios from "axios";

export const Map = ({ idResource, toggleMarkerCluster }) => {
  const mapRef = useRef(null);
  const [csvData, setCsvData] = useState();
  const markerCluster = L.markerClusterGroup();
  const [groupMarkers, setGroupMarkers] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ROOT_URL}/datastore/query/${idResource}`)
      .then((res) => {
        setCsvData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [idResource]);


  useEffect(() => {
    if (!csvData) {
      return;
    } else if (mapRef.current) {
      mapRef.current.remove();
    }

    const pointIcon = new L.Icon({
      iconUrl:
        "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
      iconSize: [20, 32],
      iconAnchor: [12, 20],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });

    const map = L.map("map").setView([-32.9442425, -60.6505388], 12);

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map);

    csvData.forEach((record, index) => {
      const geojsonKey = "geojson";
      let lat = "";
      let lon = "";
      if (record[geojsonKey]) {
        const geojson = JSON.parse(record[geojsonKey]);
        if (geojson.geometry.type === "Point") {
          // contiene geojson con objetos tipo puntos
          const marker = L.marker(
            [geojson.geometry.coordinates[1], geojson.geometry.coordinates[0]],
            {
              icon: pointIcon,
            }
          );
          let popupContent = "";
          for (let key in record) {
            if (key !== geojsonKey) {
              popupContent += `<b>${key}: </b> ${record[key]} <br />`;
            }
          }
          marker.bindPopup(popupContent, { className: "bind-popup" });
          if (groupMarkers) {
            markerCluster.addLayer(marker);
          } else {
            marker.addTo(map);
          }
        } else {
          // contiene geojson pero el objeto no es un tipo punto
          const marker = L.geoJSON(geojson);
          let popupContent = "";
          for (let key in record) {
            if (key !== geojsonKey) {
              popupContent += `<b>${key}: </b> ${record[key]} <br />`;
            }
          }
          marker.bindPopup(popupContent, { className: "bind-popup" });
          marker.addTo(map);
        }
      } else {
        if (
          record.longitude &&
          record.longitude < 0 &&
          record.latitude &&
          record.latitude < 0
        ) {
          lat = record.latitude;
          lon = record.longitude;
        } else if (
          record.punto_x &&
          record.punto_x < 0 &&
          record.punto_y &&
          record.punto_y < 0
        ) {
          lat = record.punto_x;
          lon = record.punto_y;
        }

        const marker = L.marker([lat, lon], {
          icon: pointIcon,
        });
        let popupContent = "";
        for (let key in record) {
          if (key !== geojsonKey) {
            popupContent += `<b>${key}: </b> ${record[key]} <br />`;
          }
        }
        marker.bindPopup(popupContent, { className: "bind-popup" });
        if (groupMarkers) {
          markerCluster.addLayer(marker);
        } else {
          marker.addTo(map);
        }
      }

      markerCluster.addTo(map);
    });
    mapRef.current = map;
  }, [csvData, groupMarkers, markerCluster]);

  return (
    <div>
      <div className="cluster-toggle-container">
        <input
          type="checkbox"
          id="cluster-toggle"
          checked={groupMarkers}
          onChange={(e) => setGroupMarkers(e.target.checked)}
        />
        <label htmlFor="cluster-toggle">Agrupar puntos</label>
      </div>
      <div id="map" />
    </div>
  );
};
