import React from 'react';
import { Redirect, Router } from "@reach/router";
import '@civicactions/data-catalog-components/dist/index.css';
import './theme/index.scss';
import About from './templates/about';
import SearchTemplate from './templates/search';
import NotFound from './templates/not_found';
import Dataset from './templates/dataset';
import ResourceTemplate from "./templates/resource/index";
import ApiResource from "./templates/resource_api/index";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fab, fas);

function App() {
  
  return (
    <Router>
      <NotFound path="*" />
      <About path="/acerca-de"/>
      <Redirect from="/" to="/conjunto-de-datos" />
      <SearchTemplate path="/conjunto-de-datos" />
      <Dataset path="/dataset/:id" />
      <ResourceTemplate path="/dataset/:id/recurso/:idResource" />
      <ApiResource path="/dataset/:id/recurso/:idResource/api"/>
    </Router>
  );
}

export default App;
