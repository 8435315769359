import React, { useEffect, useState } from "react";
import config from "../../assets/config";
import Layout from "../../components/Layout";
import { useLocation } from "@reach/router";
import axios from "axios";


const ApiResource = ({ idResource }) => {
  const state = useLocation().state;
  const [resource, setResource] = useState(state && state.data ? state : {});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (state && state.data) {
      setIsLoading(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_ROOT_URL}/metastore/schemas/distribution/items/${idResource}?show-reference-ids`
        )
        .then((res) => {
          setResource(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [idResource, state]);

  return (
    <Layout title={`API | Rosario Datos`}>
      {isLoading ? (
        <h1>Cargando...</h1>
      ) : (
        <div className={`dc-dataset-page ${config.container}`}>
          <div className="region region-content">
            <button className="bt-styled" onClick={() => window.history.back()}>
              Volver
            </button>
            <h2>API de almacén de datos de DKAN</h2>
            Acceda a los datos de recursos a través de una API web con un
            potente soporte de consultas.
            <h3>ID del recurso</h3>
            El ID de recurso es: {resource.identifier}
            <h3>Consulta del recurso: {resource.data.title}</h3>
            <p>
              Consulta este almacén de datos y devuelve todos los resultados
            </p>
            <button className="bt-styled">
              <a
                href={`${process.env.REACT_APP_ROOT_URL}/datastore/query/${resource.identifier}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp;API del recurso
              </a>
            </button>
            <br />
            <br />
            <a
              href={`${process.env.REACT_APP_ROOT_URL}/datastore/query/${resource.identifier}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {process.env.REACT_APP_ROOT_URL}/datastore/query/$
              {resource.identifier}
            </a>
            <h3>Documentación</h3>
            <p>
              Consulte la documentación de la API de DKAN para obtener más
              detalles:
              <a
                href="https://demo.getdkan.org/modules/contrib/dkan/docs/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp;API de almacén de datos de DKAN
              </a>
            </p>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default ApiResource;
