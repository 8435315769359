import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "@reach/router";
import Layout from "../../components/Layout";
import config from "../../assets/config";
import Organization from "../../components/Organization";
import { Spinner } from "reactstrap";
import { Text, Table, Tags } from "@civicactions/data-catalog-components";
import orgs from "../../assets/publishers";
import FileDownload from "../../components/FileDownload";
import iconosTopic from "../../assets/topics.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ZipFiles from "../../components/ZipFiles";
import DisplayBreadcrumbs from "../../components/Breadcrumbs";

const Dataset = ({ id, location }) => {
  const { state } = location;
  const [item, setItem] = useState(state && state.dataset ? state.dataset : {});
  const [hasWindow, checkForWindow] = useState(false);
  const [hasXml, checkForXml] = useState(null);
  axios.defaults.withCredentials = true;
  let displayResources = false;

  useEffect(() => {
    if (window !== undefined) {
      checkForWindow(true);
    }
    async function getItem() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/${id}?show-reference-ids`
      );
      setItem(data);
    }
    if (!state || !state.dataset) {
      getItem();
    }
  }, [id, state]);

  function handleDisplayResource() {
    if(item && item.distribution){
      item.distribution.map((dist) => {
        if (dist.data.downloadURL || dist.data.accessURL) {
          displayResources = true;
        }
        return displayResources;
      });
    }
  }

  const orgName =
    "publisher" in item && item.publisher ? item.publisher.data.name : "";
  const orgDetails = orgs.filter((org) => orgName === org.name);
  const orgDesc =
    orgDetails.length > 0 && orgDetails[0].description
      ? orgDetails[0].description
      : "";
  let renderOrg = <Organization name={orgName} description={orgDesc} />;

  const tag = "keyword" in item ? item.keyword : [];
  const theme = "theme" in item ? item.theme : [];

  function themes(theme) {
    if (!theme) {
      return null;
    } else {
      return theme.map((topic, index) => {
        let image = iconosTopic.topics.reduce(
          (url, icono) => (icono.name === topic.data ? icono.url : url),
          "/temas/ciudadanos_y_estado.png"
        );
        return (
          <div key={index} className="dc-topic-wrapper-dataset">
            <img src={image} alt={topic.data.title} />
            {topic.data}
          </div>
        );
      });
    }
  }

  function darFormatoAr(date) {
    return new Date(date).toLocaleDateString("es-AR");
  }

  const labelsT3 = {};
  const valuesT3 = {};
  if (orgName && orgName.length > 0) {
    labelsT3.publisher = { label: "Autor" };
    valuesT3.publisher = orgName;
  }
  if ("issued" in item && item.issued) {
    labelsT3.issued = { label: "Fecha de creación" };
    valuesT3.issued = darFormatoAr(item.issued);
  }
  if ("modified" in item && item.modified) {
    labelsT3.modified = { label: "Fecha de actualización" };
    valuesT3.modified = darFormatoAr(item.modified);
  }

  if ("describedBy" in item && item.describedBy) {
    labelsT3.describedBy = { label: "Diccionario de datos" };
    valuesT3.describedBy = item.describedBy;
  }
  if ("license" in item && item.license.data) {
    labelsT3.license = { label: "Licencia" };
    valuesT3.license = `<a href="${item.license.data}">${item.license.data}</a>`;
  }
  if ("contactPoint" in item && item.contactPoint && item.contactPoint.fn) {
    labelsT3.contact = { label: "Contacto" };
    valuesT3.contact = item.contactPoint.fn;
  }
  if ("contactPoint" in item && item.contactPoint && item.contactPoint.hasEmail) {
    labelsT3.email = { label: "Correo" };
  
    if (item.contactPoint.hasEmail.startsWith("mailto:")) {
      // Si la cadena comienza con "mailto:", eliminamos esa parte
      valuesT3.email = `<a href="${item.contactPoint.hasEmail.slice("mailto:".length)}">${item.contactPoint.hasEmail.slice("mailto:".length)}</a>`;
    } else {
      valuesT3.email = `<a href="${item.contactPoint.hasEmail}">${item.contactPoint.hasEmail}</a>`;
    }
  }
  
  if ("accessLevel" in item && item.accessLevel) {
    labelsT3.access = { label: "Nivel de acceso" };
    valuesT3.access = item.accessLevel;
  }
  if ("landingPage" in item && item.landingPage) {
    labelsT3.homepage = { label: "URL de Pagina" };
    valuesT3.homepage = `<a href="${item.landingPage}">${item.landingPage}</a>`;
  }

  return (
    <Layout title={`${item.title}`}>
      <div className={`dc-dataset-page ${config.container}`}>
        <DisplayBreadcrumbs title={item.title} />
        <div className="row">
          <div className="col-md-3 col-sm-12">
            {renderOrg}
            <div className="dc-block-wrapper">
              <h4>Otros accesos</h4>
              La información de esta página (metadata) también está disponible
              en estos formatos.
              <button
                className="bt-styled"
                onClick={() =>
                  window
                    .open(
                      `${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items/${item.identifier}`
                    )
                    .focus()
                }
                title="formato JSON"
              >
                JSON
              </button>
              {hasXml ? (
                <button
                  className="bt-styled"
                  onClick={() => hasXml}
                  title="formato RDF"
                >
                  RDF
                </button>
              ) : null}
            </div>
            <div className="dc-block-wrapper">
              <h3>Compartir</h3>
              <ul>
                <li>
                  <a href={`https://twitter.com/share?url=${window.location}`}>
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href={`https://plus.google.com/share?url=${window.location}`}
                  >
                    Google+
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.facebook.com/sharer.php?u=${window.location}`}
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-9 col-sm-12">
            {Object.keys(item).length ? (
              <div>
                <h1>{item.title}</h1>
                {theme.length > 0 && (
                  <div className="dc-item-theme">{themes(theme)}</div>
                )}
                {handleDisplayResource()}
                <Text value={item.description} />
                {hasWindow && item.distribution && displayResources ? (
                  <>
                    <div className="resource-wrapper">
                      <h3>Recursos</h3>
                      {item.distribution.map((dist, index) => {
                        if (dist.data.downloadURL || dist.data.accessURL) {
                          if (dist.data.format === "xml") {
                            return checkForXml(dist.data.downloadURL);
                          } else if (dist.data.format === "csv") {
                            // RECURSO COMUN PERO AGREGA PREVISUALIZAR POR SER CSV
                            return (
                              <div key={index} className="resource-container">
                                <FileDownload
                                  title={dist.data.title}
                                  label={`/dataset/${item.identifier}/recurso/${dist.identifier}`}
                                  format={dist.data.format}
                                  downloadURL={`/dataset/${item.identifier}/recurso/${dist.identifier}`}
                                />
                                <div className="dc-ResourceView">
                                  <button className="bt-styled">
                                    <Link
                                      to={`recurso/${dist.identifier}`}
                                      state={item}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fas", "newspaper"]}
                                        size="1x"
                                      />
                                      &nbsp;Previsualizar
                                    </Link>
                                  </button>
                                  <button className="bt-styled">
                                    <a href={dist.data.downloadURL}>
                                      <FontAwesomeIcon
                                        icon={["fas", "download"]}
                                        size="1x"
                                      />
                                      &nbsp;Descargar
                                    </a>
                                  </button>
                                </div>
                              </div>
                            );
                          } else {
                            //COPYURL - IR AL RECURSO
                            return (
                              <div key={index} className="resource-container">
                                <FileDownload
                                  title={dist.data.title}
                                  label={
                                    dist.data.accessURL
                                      ? dist.data.accessURL
                                      : dist.data.downloadURL
                                  }
                                  format={dist.data.format}
                                  downloadURL={
                                    dist.data.accessURL
                                      ? dist.data.accessURL
                                      : dist.data.downloadURL
                                  }
                                  target={"_blank"}
                                  description={dist.data.description}
                                />
                                <div className="dc-ResourceView">
                                  {dist.data.accessURL ? (
                                    <button className="bt-styled">
                                      <a
                                        href={
                                          dist.data.accessURL
                                            ? dist.data.accessURL
                                            : dist.data.downloadURL
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <FontAwesomeIcon
                                          icon={["fas", "paperclip"]}
                                          size="1x"
                                        />
                                        &nbsp;Ir al recurso
                                      </a>
                                    </button>
                                  ) : (
                                    <button className="bt-styled">
                                      <a href={dist.data.downloadURL}>
                                        <FontAwesomeIcon
                                          icon={["fas", "download"]}
                                          size="1x"
                                        />
                                        &nbsp;Descargar
                                      </a>
                                    </button>
                                  )}
                                </div>
                              </div>
                            );
                          }
                        } else {
                          return "";
                        }
                      })}
                      {item.distribution.length > 1 ? (
                        <div id="container-descargar-todo">
                          <ZipFiles item={item} />
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : undefined}

                <Tags
                  tags={tag}
                  path="/conjunto-de-datos?keyword="
                  label="Etiquetas"
                />
                <Table
                  title="Información adicional"
                  th1="Campo"
                  th2="Valor"
                  configuration={labelsT3}
                  data={valuesT3}
                  tableclass="metadata"
                />
                <div className="container-related-docs"></div>
              </div>
            ) : (
              <div className="row">
                <Spinner color="primary" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dataset;
