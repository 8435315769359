import { useLocation } from "@reach/router";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import logodatos from "./../../theme/images/logo_escudo_rosario.png";
import Footer from "./Footer";
import Header from "./Header";
import SecHeader from "./SecHeader";
const Layout = ({ children, title, description }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  return (
    <div className="App">
      <Helmet
        title={`${title}`}
        description={description}
        generator="DKAN 2 (https://github.com/GetDKAN/dkan)"
        defer={false}
        htmlAttributes={{
          lang: "es",
        }}
      />
      <Header logo={logodatos} />
      <SecHeader />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
