import React, { useEffect, useState } from "react";
import axios from "axios";

const Stats = () => {
  const [datasets, setDatasets] = useState(null);
  const [themes, setThemes] = useState([]);
  const [totalResources, setTotalResources] = useState(null);

  useEffect(() => {
    async function getDatasets() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items?show-reference-ids`
      );
      setDatasets(data);
      setTotalResources(
        data.reduce(
          (totalRecurces, dataset) =>
            totalRecurces +
            (dataset.distribution ? dataset.distribution.length : 0),
          0
        )
      );
    }
    async function getThemes() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/metastore/schemas/theme/items`
      );
      setThemes(data);
    }
    if (datasets === null) {
      getDatasets();
      getThemes();
    }
  }, [datasets, themes]);

  return (
    <>
      {totalResources ? (
        <div className="stat-block-container">
          <div className="stat stat-1">
            <h2>{themes.length}</h2>
            <p>Temáticas de datos</p>
          </div>
          <div className="stat stat-2">
            <h2>{datasets.length}</h2>
            <p>Conjuntos de datos</p>
          </div>
          <div className="stat stat-3">
            <h2>{totalResources}</h2>
            <p>Recursos</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Stats;
